import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SocketioService } from 'src/app/services/socketio/socketio.service';
import { GoogleMapService } from '../../services/google-map/google-map.service';
import { BannerOverlay } from './banner-overlay';
import { TRIP_STATUS_BG_COLOR } from 'src/app/models/assigned-trips.model';
export interface IMapConfig {
    directionViewType?: 'normal' | 'banner';
    id: string; // assign name of the parent component in order to debug the scenario if needed
    pickup_lat?: string | number;
    pickup_lng?: string | number;
    pickup_location?: string;

    dropoff_lat?: string | number;
    dropoff_lng?: string | number;
    dropoff_location?: string;

    pickup_datetime?: Date | any;

    enable_driver_view?: boolean;
    isPinDragEnabled?: boolean;
    show_online_driver_only: boolean;
    driver?: {
        id: number | string;
        driver_mobile: number | string;
        driver_name: number | string;
    };

    center?: { lat: number; lng: number };
    zoom?: number;
    travelMode?: google.maps.TravelMode.DRIVING;
    avoidTolls?: boolean;
    avoidHighways?: boolean;
    show_driver_infowindow?: boolean;
    show_custom_banner?: boolean;
    mapTypeId?: google.maps.MapTypeId;
    milestones?: Array<{
        lat: number;
        lng: number;
        title?: string;
        icon?: string;
        type?: string;
        anchorPoint?: google.maps.Point;
        location?: string;
    }>;
    polylines?: Array<{
        lat: number;
        lng: number;
    }>;
    milestone_text?: string;
}

export interface I_ETA_VALUE {
    text: string;
    value: number;
}

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit, OnDestroy, OnChanges {
    constructor(private googleMapService: GoogleMapService, private socketService: SocketioService) {}
    // @ViewChild('mapElement') mapElement: ElementRef;
    map: google.maps.Map;
    directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();
    directionsDisplay: google.maps.DirectionsRenderer;

    center: { lat: number; lng: number } = { lat: 40.73221, lng: -73.91902 };
    zoom = 11;
    getDriverInterval: any;

    @Input() mapConfig: IMapConfig;
    @Output() driver_pickup_ETA: EventEmitter<I_ETA_VALUE> = new EventEmitter<I_ETA_VALUE>();
    @Output() pickup_dropoff_ETA: EventEmitter<I_ETA_VALUE> = new EventEmitter<I_ETA_VALUE>();
    @Output() encodedPolyline: any = new EventEmitter<any>();

    markers: Array<{
        path?: string;
        marker: google.maps.Marker;
        time: number;
        isMoving?: boolean;
        driver_id: number | string;
        infowindow: google.maps.InfoWindow;
    }> = [];
    bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    driver: any;
    milestonePolyline: google.maps.Polyline;

    ngOnInit(): void {
        // this.initializeGoogleMap();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.mapConfig?.currentValue) {
            this.mapConfig = changes?.mapConfig?.currentValue as IMapConfig;
            this.cleanup();

            this.mapConfig.show_online_driver_only = true;

            setTimeout(() => {
                this.initializeGoogleMap();
            }, 0);
        }
    }

    @ViewChild('mapElement') mapElement: ElementRef;
    initializeGoogleMap() {
        if (this.mapConfig?.enable_driver_view && this.mapConfig?.driver?.id) {
            this.directionsDisplay = new google.maps.DirectionsRenderer({ preserveViewport: true, suppressMarkers: true });
        } else {
            this.directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });
        }

        this.map = new google.maps.Map(this.mapElement?.nativeElement, {
            center: this.mapConfig?.center || this.center, //{ lat: -34.397, lng: 150.644 },
            zoom: this.mapConfig?.zoom || this.zoom,
            mapTypeId: this.mapConfig?.mapTypeId || google.maps.MapTypeId.TERRAIN,
            mapTypeControl: false,
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            streetViewControl: false,
        });

        if (this.mapConfig?.show_custom_banner) {
            const bannerControlDiv = document.createElement('div');
            bannerControlDiv.appendChild(this.bannerViewControl(this.map));
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(bannerControlDiv);
        }
        this.directionsDisplay.setMap(this.map);

        // const mapOptions: google.maps.MapOptions = {
        //     center: { lat: 37.39094933041195, lng: -122.02503913145092 },
        //     zoom: 14,
        //     mapId: '4504f8b37365c3d0',
        //   };

        //   const map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

        //   const infoWindow = new google.maps.InfoWindow();

        //   const markerOptions: google.maps.MarkerOptions = {
        //     map,
        //     position: { lat: 37.39094933041195, lng: -122.02503913145092 },
        //     draggable: true,
        //     title: "This marker is draggable.",
        //   };

        //   const marker = new google.maps.Marker(markerOptions);

        //   marker.addListener('dragend', () => {
        //     const position = marker.getPosition();
        //     if (position) {
        //       infoWindow.close();
        //       infoWindow.setContent(`Pin dropped at: ${position.lat()}, ${position.lng()}`);
        //       infoWindow.open(map, marker);
        //     }
        //   });

        if (this.mapConfig?.milestones?.length) {
            this._show_milestone_polylines();
        } else {
            this._show_pickup_dropoff();
        }

        if (this.mapConfig?.enable_driver_view) {
            setTimeout(() => {
                this.getLiveDrivers();
                this.listenDriverUpdates();
            }, 0);
        }
    }

    private pinDragable() {}

    private _show_pickup_dropoff() {
        const pickup = this.mapConfig.pickup_lat + ',' + this.mapConfig.pickup_lng;
        const dropoff = this.mapConfig.dropoff_lat + ',' + this.mapConfig.dropoff_lng;
        const request: google.maps.DirectionsRequest = {
            origin: pickup,
            destination: dropoff,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls: this.mapConfig.avoidTolls || false,
            avoidHighways: this.mapConfig?.avoidHighways ?? this.googleMapService.defaultAvoidHighways,
        };
        // this.trip.polylines = 'NOT FOUND YET';
        this.directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.pickup_dropoff_ETA.emit({
                    text: this.googleMapService.secondsToHms(response.routes?.[0].legs?.[0]?.duration?.value),
                    value: response.routes?.[0].legs?.[0]?.duration?.value,
                });
                this.directionsDisplay.setDirections(response);

                const pickupBannerElement = this._getLocationBanner(
                    '/assets/images/milestones_svgs/request_pickup.svg',
                    'Requested Pickup',
                    this.mapConfig?.pickup_location
                );
                const pickupOverlay = new BannerOverlay(
                    new google.maps.LatLng(Number(this.mapConfig?.pickup_lat), Number(this.mapConfig?.pickup_lng)),
                    pickupBannerElement,
                    this.map
                );

                const dropoffBannerElement = this._getLocationBanner(
                    '/assets/images/milestones_svgs/request_dropoff.svg',
                    'Requested Dropoff',
                    this.mapConfig?.dropoff_location
                );
                const dropoffOverlay = new BannerOverlay(
                    new google.maps.LatLng(Number(this.mapConfig?.dropoff_lat), Number(this.mapConfig?.dropoff_lng)),
                    dropoffBannerElement,
                    this.map
                );

                // const overlay = new CustomOverlay(bounds, overlayElement, this.map);
                // overlay.setMap(this.map);
            }
        });
    }

    milestoneMarkers: any[] = [];
    polylineTimer: any;
    private _show_milestone_polylines() {
        this.directionsDisplay.setMap(null);
        this.bounds = new google.maps.LatLngBounds();
        this.mapConfig.milestones.forEach((item) => {
            if (item.lat && item.lng) {
                if (item?.title == 'RP') {
                    const bannerElement = this._getLocationBanner(
                        '/assets/images/milestones_svgs/request_pickup.svg',
                        'Requested Pickup',
                        item?.location
                    );
                    const position = new google.maps.LatLng(item.lat, item.lng);
                    const overlay = new BannerOverlay(position, bannerElement, this.map);
                } else if (item?.title === 'RD') {
                    const bannerElement = this._getLocationBanner(
                        '/assets/images/milestones_svgs/request_dropoff.svg',
                        'Requested Dropoff',
                        item?.location
                    );
                    const position = new google.maps.LatLng(item.lat, item.lng);
                    const overlay = new BannerOverlay(position, bannerElement, this.map);
                } else {
                    const marker = new google.maps.Marker({
                        position: { lat: Number(item.lat), lng: Number(item.lng) },
                        map: this.map,
                        title: item.title,
                        icon: {
                            url: item?.icon,
                            scaledSize: new google.maps.Size(24, 24),
                            anchor: item?.anchorPoint,
                        },
                        zIndex: -1,
                    });
                    this.milestoneMarkers.push(marker);
                }
                this.bounds.extend(new google.maps.LatLng(Number(item.lat), Number(item.lng)));
            }
        });

        this.map.fitBounds(this.bounds);
        this.map.setZoom(this.zoom);

        const polylinePositions = this.mapConfig?.polylines;

        this.milestonePolyline = new google.maps.Polyline({
            path: [],
            geodesic: true,
            strokeColor: '#334D6E',
            strokeOpacity: 1.0,
            strokeWeight: 3.5,
            map: this.map,
        });
        if (polylinePositions?.length) {
            let pointer = 0;
            this.polylineTimer = setInterval(() => {
                const currentCoordinate = polylinePositions[pointer];
                if (currentCoordinate?.lat && currentCoordinate?.lng) {
                    const path = this.milestonePolyline.getPath();
                    path.push(new google.maps.LatLng({ lat: currentCoordinate?.lat, lng: currentCoordinate?.lng }));
                    this.milestonePolyline.setPath(path);
                }
                pointer++;
                if (pointer >= polylinePositions?.length) {
                    clearInterval(this.polylineTimer);

                    // EMIT ENCODED POLYLINE
                    this.encodedPolyline.emit(google.maps.geometry.encoding.encodePath(this.milestonePolyline.getPath()));
                }
            }, 10);
        }
    }

    private _getLocationBanner(img: string, label: string, location: string) {
        const div = document.createElement('div');
        div.classList.add('custom-marker');
        div.innerHTML = `<div class="hand-banner">
        <div class="rectangle">
            <div class="d-flex align-items-center">
                <div> <img src="${img}" /> </div>
                <div class="ml-1 fs-11 fw-600 ${label === 'Requested Pickup' ? 'text-green' : 'text-red2'}">${label}</div>
            </div>
            <div class="text-dark-blue mt-1 ml-1"> ${location} </div>
        </div>
            <div class="pipe"></div>
        </div>`;
        return div;
    }

    public getLiveDrivers() {
        // Internal function for code resuability

        const driver_payload: any = {
            session_id: undefined,
            offset: 0,
        };
        if (this.mapConfig?.driver?.id && (this.mapConfig?.driver?.driver_mobile || this.mapConfig?.driver?.driver_name)) {
            driver_payload.searchFlag = 1;
            driver_payload.searchString = this.mapConfig?.driver?.driver_mobile || this.mapConfig?.driver?.driver_name;
            driver_payload.limit = 10;
        } else if (this.mapConfig.show_online_driver_only) {
            driver_payload.is_available = 1;
        }

        const driverEmittor = () => {
            this.socketService.emit('corporateDriverAvailablity', driver_payload);
        };

        driverEmittor();
        this.getDriverInterval = setInterval(() => {
            driverEmittor();
        }, this.socketService.intervalDuration);
    }

    driverAvailablitySubscription: Subscription;
    listenDriverUpdates() {
        this.driverAvailablitySubscription = this.socketService.on('corporateDriverAvailablity').subscribe(async (data) => {
            // console.log('corporateDriverAvailablity => ', data);
            const driver_list: Array<any> = data.data.drivers || [];
            console.log('driver_list => ', driver_list);

            driver_list.forEach((driver) => {
                this.syncDriverMarker(driver, Number(driver?.driver_id) === Number(this.mapConfig?.driver?.id));
                if (Number(driver?.driver_id) === Number(this.mapConfig?.driver?.id)) {
                    this.driver = driver;
                }
            });

            if (!this.mapConfig?.milestones?.length && this.driver) {
                const eta = await this.getETA('driver_to_pickup', this.driver);
                this.driver_pickup_ETA.next(eta as I_ETA_VALUE);
            }
        });
    }

    getETA(type: 'driver_to_pickup' | 'driver_to_dropoff', driver = this.driver) {
        const origin = driver.current_location_latitude + ',' + driver.current_location_longitude;
        let destination = this.mapConfig?.pickup_lat + ',' + this.mapConfig?.pickup_lng;

        if (type === 'driver_to_dropoff') {
            destination = this.mapConfig?.dropoff_lat + ',' + this.mapConfig?.dropoff_lng;
        }

        const request: google.maps.DirectionsRequest = {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            transitOptions: {
                arrivalTime: new Date(this.mapConfig?.pickup_datetime),
            },
            avoidHighways: this.mapConfig?.avoidHighways ?? this.googleMapService.defaultAvoidHighways,
            avoidTolls: this.mapConfig?.avoidTolls ?? this.googleMapService.defaultAvoidTolls,
        };
        return new Promise((resolve, reject) => {
            this.directionsService.route(request, (response: any, status: any) => {
                if (status == google.maps.DirectionsStatus.OK) {
                    const leg = response.routes?.[0].legs?.[0];
                    resolve(leg?.duration);
                } else {
                    resolve(null);
                }
            });
        });
    }

    get pickup_or_dropoff_eta() {
        if (this.mapConfig?.milestone_text === 'Picking Up') {
            return 'pickup';
        } else if (this.mapConfig?.milestone_text === 'En Route') {
            return 'dropoff';
        }
        return '';
    }

    initialDriverLoading: boolean = true;
    async syncDriverMarker(driver: any, is_active_driver: boolean = false) {
        let url: string;
        let urlGoing: string;
        const image: any = {
            url: url,
            scaledSize: new google.maps.Size(33, 33),
            anchor: new google.maps.Point(16.5, 16.5),
        };

        if (driver.is_free == 1) {
            if (driver.car_type == 2) {
                url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                urlGoing = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
            } else if (driver.car_type == 1) {
                url = 'assets/img/driver_idle.svg';
                urlGoing = 'assets/img/driver_intransit.svg';
            } else if (driver.car_type == 3) {
                url = 'assets/carTypeImage/LUXE/3_White_LUXE.svg';
                urlGoing = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
            } else if (driver.car_type == 4) {
                url = 'assets/carTypeImage/Grande/3_White_Grande.svg';
                urlGoing = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
            } else {
                url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                urlGoing = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
            }
        } else {
            if (driver.car_type == 1) {
                url = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
            } else if (driver.car_type == 2) {
                url = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
            } else if (driver.car_type == 3) {
                url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
            } else {
                url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
            }
        }

        const bgClass = TRIP_STATUS_BG_COLOR[this.mapConfig.milestone_text];
        let driver_metrics: any;
        if (this.pickup_or_dropoff_eta === 'pickup') driver_metrics = await this.getETA('driver_to_pickup', driver);
        else if (this.pickup_or_dropoff_eta === 'dropoff') driver_metrics = await this.getETA('driver_to_dropoff', driver);

        let content = `
          <div id="${driver.driver_id}">
            ${
                ['En Route', 'Picking Up']?.includes(this.mapConfig?.milestone_text)
                    ? `<div class="text-white p-1 ${bgClass}">
                ${driver_metrics?.text}
            </div>`
                    : ''
            }
            ${this.mapConfig?.milestone_text === 'Arrived' ? `<div class="text-white p-1 ${bgClass}"> Arrived </div>` : ''}
            <div class="p-1"> ${driver.driver_name.slice(0, 20)} </div>
          </div>
        `;

        let infowindow: google.maps.InfoWindow = new google.maps.InfoWindow({
            content,
            disableAutoPan: true,
        });
        image.url = `${url}#${driver.driver_id}`;

        const marker: google.maps.Marker = this.setMarker(driver, { image, infowindow });

        if (is_active_driver) {
            // If Polyline is being constructed then sync the polyline using the latest driver location
            if (this.milestonePolyline) {
                const path = this.milestonePolyline.getPath();
                path.push(new google.maps.LatLng(driver.current_location_latitude, driver.current_location_longitude));
                this.milestonePolyline.setPath(path);

                // EMIT ENCODED POLYLINE
                this.encodedPolyline.emit(google.maps.geometry.encoding.encodePath(this.milestonePolyline.getPath()));
            }

            if (this.initialDriverLoading) {
                if (!this.mapConfig?.milestones?.length) {
                    this.bounds = new google.maps.LatLngBounds();
                }
                this.bounds.extend(new google.maps.LatLng(Number(this.mapConfig?.pickup_lat), Number(this.mapConfig?.pickup_lng)));
                this.bounds.extend(new google.maps.LatLng(Number(this.mapConfig.dropoff_lat), Number(this.mapConfig.dropoff_lng)));
                this.bounds.extend(marker.getPosition());
                this.map.fitBounds(this.bounds);

                this.map.setCenter(this.bounds.getCenter());
                this.map.setZoom(this.map.getZoom() - 0.5);
                this.initialDriverLoading = false;
            }

            console.log('Dynamic Zoom check', this.mapConfig?.milestone_text);
            if (['Picking Up', 'En Route', 'Arrived', 'At Dropoff']?.includes(this.mapConfig?.milestone_text)) {
                if (['Picking Up', 'Arrived']?.includes(this.mapConfig?.milestone_text)) {
                    console.log('Zoom Driver to Picking up');
                    this.bounds = new google.maps.LatLngBounds();
                    this.bounds.extend(new google.maps.LatLng(Number(driver.current_location_latitude), Number(driver.current_location_longitude)));
                    this.bounds.extend(new google.maps.LatLng(Number(this.mapConfig?.pickup_lat), Number(this.mapConfig?.pickup_lng)));
                } else if (['En Route', 'At Dropoff']?.includes(this.mapConfig?.milestone_text)) {
                    console.log('Zoom Driver to Dropoff');
                    this.bounds = new google.maps.LatLngBounds();
                    this.bounds.extend(new google.maps.LatLng(Number(driver.current_location_latitude), Number(driver.current_location_longitude)));
                    this.bounds.extend(new google.maps.LatLng(Number(this.mapConfig?.dropoff_lat), Number(this.mapConfig?.dropoff_lng)));
                }

                this.map.fitBounds(this.bounds);
                this.map.setCenter(this.bounds.getCenter());
                this.map.setZoom(this.map.getZoom() || 0);
            }
        }
    }

    setMarker(driver: any, { image, infowindow }): google.maps.Marker {
        // set old marker to null
        // this.markers.forEach((m) => m.marker.setMap(null));

        const driver_location = new google.maps.LatLng(driver.current_location_latitude, driver.current_location_longitude);

        const driver_marker = this.markers?.find((marker) => marker.driver_id === Number(driver?.driver_id));
        if (driver_marker) {
            const markerRef = driver_marker; // this.markers?.[0];
            let prevPos: any = markerRef.marker.getPosition();
            if (markerRef?.path?.length > 0) {
                prevPos = markerRef.path[markerRef.path.length - 1][0];
            }
            let icon: any = markerRef.marker.getIcon();
            icon.url = image.url;
            markerRef.marker.setIcon(icon);
            markerRef.marker.setMap(this.map);
            // var rotation = google.maps.geometry.spherical.computeHeading(prevPos, location);
            let rotation = google.maps.geometry.spherical.computeHeading(prevPos, driver_location);
            let fromLat = prevPos.lat();
            let fromLng = prevPos.lng();
            let toLat = driver_location.lat();
            let toLng = driver_location.lng();
            if (fromLat != toLat || fromLng != toLng) {
                let diff = Date.now() - markerRef.time;
                markerRef.time = Date.now();
                let frames: any = markerRef.path || [];
                let hasPath = false;
                if (frames?.length > 0) {
                    hasPath = true;
                }
                if (diff > 2000) {
                    diff = 1000;
                }
                if (frames.length >= 100) {
                    frames = [];
                }

                for (let percent = 0; percent < 1; percent += 0.01) {
                    let curLat = fromLat + percent * (toLat - fromLat);
                    let curLng = fromLng + percent * (toLng - fromLng);
                    frames.push([new google.maps.LatLng(curLat, curLng), rotation]);
                }
                markerRef.path = frames;
                if (!hasPath) {
                    this.move(markerRef, diff / 100);
                } else if (!markerRef.isMoving) {
                    this.move(markerRef, diff / 100);
                } else {
                    this.move(markerRef, 0.5);
                }
            }
            this.googleMapService.toggle_infowindow(driver_marker?.infowindow, 'hide', { map: this.map, anchor: driver_marker?.marker });
            this.googleMapService.toggle_infowindow(infowindow, 'show', { map: this.map, anchor: driver_marker?.marker });
            driver_marker.infowindow = infowindow;
            return markerRef.marker;
        } else {
            const marker: google.maps.Marker = new google.maps.Marker({
                position: driver_location,
                icon: image,
                map: this.map,
            });
            this.markers.push({
                time: Date.now(),
                marker,
                infowindow,
                driver_id: driver?.driver_id,
            });

            marker.addListener('click', () => {
                this.googleMapService.toggle_infowindow(infowindow, 'show', {
                    anchor: marker,
                    map: this.map,
                    shouldFocus: false,
                });
            });
            if (this.mapConfig?.show_driver_infowindow) {
                this.googleMapService.tigger_marker(marker);
            }
            return marker;
        }
    }

    private move = (markerRef: { path?: any; time: number; marker: google.maps.Marker; isMoving?: boolean }, wait: any) => {
        if (markerRef.path.length > 0) {
            markerRef.isMoving = true;
            markerRef.marker.setPosition(markerRef.path[0][0]);
            const iconRef: any = markerRef.marker.getIcon();
            const markerImg: any = document.querySelector(`img[src="${iconRef?.url}"]`);
            if (markerImg) {
                const deg = markerRef.path[0][1];
                markerImg.style.transform = 'rotate(' + deg + 'deg)';
            }
            markerRef.path.splice(0, 1);
            setTimeout(() => {
                this.move(markerRef, wait);
            }, wait);
            let icon = markerRef.marker.getIcon();
            markerRef.marker.setIcon(icon);
        } else {
            markerRef.isMoving = false;
        }
    };

    bannerViewControl(map: google.maps.Map = this.map) {
        const controlButton = document.createElement('div');

        // Set CSS for the control.
        controlButton.style.backgroundColor = '#fff';
        controlButton.style.border = '2px solid #fff';
        controlButton.style.borderRadius = '3px';
        controlButton.style.boxShadow = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px';
        controlButton.style.color = 'rgb(25,25,25)';
        controlButton.style.cursor = 'pointer';
        controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlButton.style.fontSize = '16px';
        controlButton.style.lineHeight = '36px';
        controlButton.style.margin = '8px 10px 24px';
        controlButton.style.padding = '0 5px';
        // controlButton.style.position = 'absolute';
        // controlButton.style.bottom = '0px';
        // controlButton.style.right = '-348px';
        // controlButton.style.right = '-17vw';

        controlButton.setAttribute('id', 'bannerViewIcon');
        controlButton.innerHTML = this.vibilityIcon; // `<img src="assets/images/visibility.svg" style="width: 28px; height: 28px;">`;

        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener('click', () => {
            this.mapConfig.show_driver_infowindow = !this.mapConfig.show_driver_infowindow;
            controlButton.innerHTML = this.vibilityIcon;
            // console.log("show driver => ", this.show_driver_infowindow);
            console.log('show_driver_infowindow => ', this.mapConfig?.show_driver_infowindow);
            this.markers.forEach((marker_ref: any) => {
                this.googleMapService.toggle_infowindow(marker_ref?.infowindow, this.mapConfig?.show_driver_infowindow ? 'show' : 'hide', {
                    anchor: marker_ref?.marker,
                    map: this.map,
                });
            });
        });

        return controlButton;
    }

    private get vibilityIcon(): string {
        return `<img src="assets/images/${
            this.mapConfig?.show_driver_infowindow ? 'visibility_off.svg' : 'visibility.svg'
        }" style="width: 28px; height: 28px;">`;
    }

    ngOnDestroy(): void {
        this.cleanup();
    }

    cleanup() {
        clearInterval(this.getDriverInterval);
        if (this.driverAvailablitySubscription) this.driverAvailablitySubscription.unsubscribe();
        this.markers.forEach((marker) => {
            marker?.marker?.setMap(null);
        });
        this.initialDriverLoading = true;
        if (this.directionsDisplay) {
            this.directionsDisplay.setMap(null);
        }

        if (this.polylineTimer) {
            clearInterval(this.polylineTimer);
        }
    }
}
